import CONFIG from '../config'
import { Role } from '../interfaces/Role'
import { User_users } from '../hasura/graphQlQueries/types/User'
import { isEnterpriseAdmin, isUser } from './helpers'

export const shouldDisplayTurnaroundTimes = (user?: User_users, role?: Role) =>
  !isEnterpriseAdmin(role) || user?.organization.enterprise.enterprise_admin_turnaround_times_on_invoices

export const getVetspirePimsOrganization = (user?: User_users) => user?.organization.pims_organizations.find((p) => p.vetspire_api_url)

export const isRadimalReaderLoggedInOtherEnterprise = (user?: User_users) =>
  user?.organization.enterprise.id !== CONFIG.RADIMAL_ENTERPRISE_ID &&
  user?.user_roles.some((r) => r.enterprise.id === CONFIG.RADIMAL_ENTERPRISE_ID)

export const hidePriceControlsFromEnterpriseUser = (role: Role, user?: User_users) =>
  isUser(role) && user?.organization.enterprise.only_enterprise_admins_can_handle_pricing
