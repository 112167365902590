import { createSlice } from '@reduxjs/toolkit'

import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import callFlask from '../callFlask'

export interface MachineLearningState {
  isQuerying: any
  mlServerStatus?: string
  singleGpuMlServerStatus?: string
}

const initialState: MachineLearningState = {
  isQuerying: {},
}

const machineLearningSlice = createSlice({
  name: 'machineLearning',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    setMlServerStatus: (state, { payload }) => {
      state.mlServerStatus = payload
    },

    setSingleGpuMlServerStatus: (state, { payload }) => {
      state.singleGpuMlServerStatus = payload
    },
  },
})

export const {
  networkingFailure,
  networkingSuccess,
  setLoading,
  setMlServerStatus,
  setSingleGpuMlServerStatus,
} = machineLearningSlice.actions

export const machineLearningSelector = (state: any) => state.machineLearning

export default machineLearningSlice.reducer

export function fetchMLServerStatusAction(server: string) {
  return async (dispatch: any) => {
    const result = await callFlask(`/ml-training-server/status`, 'POST', { server })
    if (result.status) {
      if (server === 'single-gpu') {
        dispatch(setSingleGpuMlServerStatus(result.status))
      } else {
        dispatch(setMlServerStatus(result.status))
      }
    } else {
      dispatch(networkingFailure())
    }
  }
}

export function updateMLServerStatusAction(on: boolean, server: string) {
  return async (dispatch: any) => {
    await callFlask(`/ml-training-server/${on ? 'stop' : 'start'}`, 'POST', { server })
    dispatch(fetchMLServerStatusAction(server))
  }
}
