import gql from 'graphql-tag'

import { price_groups_insert_input, prices_insert_input } from '../../../types/globalTypes'
import { QueryName } from '../queryNames'
import { PRICE_GROUP_FIELDS } from './fragments/priceGroupFields'

export const fetchStatTypesQuery = () => ({
  query: gql`
    query StatTypes {
      stat_types {
        id
        display_name
        hours
      }
    }
  `,
  route: 'stat_types',
})

export const fetchAddonsQuery = () => ({
  query: gql`
    query Addons {
      addons {
        id
        display_name
        additional_data
      }
    }
  `,
  route: 'addons',
})

export const insertPriceGroupQuery = (object: price_groups_insert_input) => ({
  query: gql`
    mutation InsertPriceGroup($object: price_groups_insert_input!) {
      insert_price_groups_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_price_groups_one',
  variables: { object },
})

export const insertPricesQuery = (objects: prices_insert_input[], name = QueryName.InsertPrice) => ({
  query: gql`
    mutation InsertPrices($objects: [prices_insert_input!]!) {
      insert_prices(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  route: 'insert_prices',
  variables: { objects },
  name,
})

export const updatePriceAmountQuery = (id: number, amount: number) => ({
  query: gql`
    mutation UpdatePriceAmount($id: Int!, $amount: float8!) {
      update_prices_by_pk(pk_columns: { id: $id }, _set: { amount: $amount }) {
        id
      }
    }
  `,
  route: 'update_prices_by_pk',
  variables: { id, amount },
})

export const deletePriceGroupQuery = (id: number) => ({
  query: gql`
    mutation DeletePriceGroup($id: Int!) {
      delete_price_groups_by_pk(id: $id) {
        id
      }
    }
  `,
  route: 'delete_price_groups_by_pk',
  variables: { id },
})

export const deletePricesQuery = (ids: number[]) => ({
  query: gql`
    mutation DeletePrices($ids: [Int!]!) {
      delete_prices(where: { id: { _in: $ids } }) {
        affected_rows
      }
    }
  `,
  route: 'delete_prices',
  variables: { ids },
})

export const updateDefaultPriceGroupQuery = (enterprise_id: number, default_price_group_id: number) => ({
  query: gql`
    mutation UpdateDefaultPriceGroup($enterprise_id: Int!, $default_price_group_id: Int!) {
      update_enterprises_by_pk(pk_columns: { id: $enterprise_id }, _set: { default_price_group_id: $default_price_group_id }) {
        id
      }
    }
  `,
  route: 'update_enterprises_by_pk',
  variables: { enterprise_id, default_price_group_id },
})

export const updateOrganizationPriceGroupQuery = (organization_id: number, price_group_id: number) => ({
  query: gql`
    mutation UpdateOrganizationPriceGroup($organization_id: Int!, $price_group_id: Int!) {
      update_organizations_by_pk(pk_columns: { id: $organization_id }, _set: { price_group_id: $price_group_id }) {
        id
      }
    }
  `,
  route: 'update_organizations_by_pk',
  variables: { organization_id, price_group_id },
})

export const fetchPriceGroupQuery = (price_group_id: number) => ({
  query: gql`
    ${PRICE_GROUP_FIELDS}
    query PriceGroup($price_group_id: Int!) {
      price_groups(where: { id: { _eq: $price_group_id } }) {
        ...price_group_fields
      }
    }
  `,
  route: 'price_groups',
  variables: { price_group_id },
})
