import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import { createSlice } from '@reduxjs/toolkit'
import { navigate } from 'gatsby'

import CONFIG from '../../config'
import callFlask from '../callFlask'
import callHasura from '../callHasura'
import { CaseQuestion_case_questions_by_pk } from '../graphQlQueries/types/CaseQuestion'
import { CaseQuestions_case_questions } from '../graphQlQueries/types/CaseQuestions'
import { Case_cases } from '../graphQlQueries/types/Case'
import { NotificationId, setNotificationAction } from './notifications'
import { QueryName } from '../queryNames'
import { TrainingIterations_training_iterations } from '../graphQlQueries/types/TrainingIterations'
import { defaultNetworkingFailure, defaultNetworkingSuccess, defaultSetLoading } from './common'
import { fetchImagesAction } from './consultations'
import { isDemoAccount, keyForAwsS3Url, postSentryError } from '../../lib/helpers'

import {
  claimCaseQuestionQuery,
  fetchCaseQuery,
  fetchCaseQuestionQuery,
  fetchCaseQuestionsQuery,
  fetchCasesQuery,
  updateCaseQuestionQuery,
} from '../graphQlQueries/Case'

type ActionResult = [success: boolean, message: string]

export type VetDataTask = CaseQuestions_case_questions | TrainingIterations_training_iterations

export const instanceOfCaseQuestion = (task: VetDataTask): task is CaseQuestions_case_questions =>
  'text' in task || 'case_questions_denormalized' in task

export interface CasesState {
  accessToken?: string
  loading: boolean
  cases: Case_cases[]
  radimalCase?: Case_cases
  totalCases: number
  isQuerying: any
  caseQuestions: CaseQuestions_case_questions[]
  postInstanceResult?: ActionResult
  caseQuestion?: CaseQuestion_case_questions_by_pk
}

const initialState: CasesState = {
  isQuerying: {},
  totalCases: 0,
  loading: false,
  cases: [],
  caseQuestions: [],
}

const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,
    setLoading: defaultSetLoading,

    fetchCasesSuccess: (state, { payload }) => {
      state.cases = payload.cases
      state.totalCases = payload.count
    },

    setCase: (state, { payload }: { payload: Case_cases | undefined }) => {
      state.radimalCase = payload
    },

    unsetCase: (state) => {
      state.radimalCase = undefined
    },

    setPostInstanceResult: (state, { payload }: { payload: ActionResult | undefined }) => {
      state.postInstanceResult = payload
    },

    fetchCaseQuestionsSuccess: (state, { payload }: { payload: CaseQuestions_case_questions[] }) => {
      state.caseQuestions = payload
    },

    fetchCaseQuestionSuccess: (state, { payload }: { payload: CaseQuestion_case_questions_by_pk }) => {
      state.caseQuestion = payload
    },
  },
})

export const {
  fetchCasesSuccess,
  setPostInstanceResult,
  networkingFailure,
  networkingSuccess,
  fetchCaseQuestionsSuccess,
  fetchCaseQuestionSuccess,
  setLoading,
  setCase,
  unsetCase,
} = casesSlice.actions

export const casesSelector = (state: any) => state.cases

export default casesSlice.reducer

export function fetchCaseAction(accessToken: string, id: number, fetchImages: boolean) {
  return async (dispatch: any) => {
    const queryName = QueryName.FetchCase
    dispatch(setLoading(queryName))

    try {
      const result: Case_cases[] = await callHasura(accessToken, fetchCaseQuery(id))

      if (result.length) {
        if (fetchImages) {
          const keys = compact(
            compact(flatten(result[0].patient.cases.map((c) => c.medical_images.map((m) => m.aws_s3_url)))).map(keyForAwsS3Url)
          )
          dispatch(fetchImagesAction(keys))
        }

        dispatch(setCase(result[0]))
      }

      dispatch(networkingSuccess(queryName))
    } catch (error) {
      dispatch(networkingFailure(queryName))
    }
  }
}

export function fetchCasesAction(
  accessToken: string,
  createdAt: string,
  enterprise_id?: number,
  organization_id?: number,
  cursor?: number,
  patientId?: number,
  conditionId?: number
) {
  return async (dispatch: any) => {
    const query = fetchCasesQuery(createdAt, enterprise_id, organization_id, patientId, conditionId, cursor)
    dispatch(setLoading(query.name))

    try {
      let { cases, cases_aggregate } = await callHasura(accessToken, query)
      if (isDemoAccount(accessToken)) {
        cases = compact(CONFIG.DEMO_ORGANIZATION_CASE_IDS.map((id) => cases.find((c: any) => c.id === id)))
      }
      dispatch(fetchCasesSuccess({ cases, count: cases_aggregate.aggregate.count }))
      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

interface PostInstanceResult {
  ID: string
  ParentPatient: string
  ParentSeries: string
  ParentStudy: string
  Path: string
  Status: string
  Message?: string
}

export function setPostInstanceResultAction(result?: ActionResult) {
  return async (dispatch: any) => {
    dispatch(setPostInstanceResult(result))
  }
}

export function unsetCaseAction() {
  return async (dispatch: any) => {
    dispatch(unsetCase())
  }
}

export function postInstanceAction(formData: FormData, ipAddress: string, aet?: string) {
  return async (dispatch: any) => {
    const queryName = QueryName.PostInstance
    dispatch(setLoading(queryName))

    try {
      const result: PostInstanceResult = await callFlask(`/orthanc/instance/api-upload`, 'POST', undefined, formData)
      if (result.Status === 'Success') {
        await callFlask(`/orthanc/instance?ip_address=${ipAddress}&aet=${aet}`, 'POST', result)
        dispatch(
          setPostInstanceResult([true, 'DICOM successfully uploaded. Please wait a moment for it to appear in your dashboard.'])
        )
      } else if (result.Message === 'Inexistent tag') {
        dispatch(
          setPostInstanceResult([
            false,
            'Issue reading file - are you sure this is a DICOM? Please message us for further assistance.',
          ])
        )
      } else if (result.Status === 'AlreadyStored') {
        dispatch(setPostInstanceResult([false, 'DICOM already uploaded.']))
      } else {
        postSentryError(`Post instance error ${result.Message}`)
        dispatch(setPostInstanceResult([false, `Something bad happened: ${result.Message}.`]))
      }
      dispatch(networkingSuccess(queryName))
    } catch (error) {
      dispatch(setPostInstanceResult([false, 'Something bad happened.']))
      dispatch(networkingFailure(queryName))
    }
  }
}

export function fetchCaseQuestionsAction(accessToken: string, userId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: CaseQuestions_case_questions[] = await callHasura(accessToken, fetchCaseQuestionsQuery(userId))
      dispatch(fetchCaseQuestionsSuccess(result.filter((r) => !r.exclude_vet_ids_denormalized?.includes(userId))))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchCaseQuestionAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: CaseQuestion_case_questions_by_pk = await callHasura(accessToken, fetchCaseQuestionQuery(id))
      dispatch(fetchCaseQuestionSuccess(result))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function claimCaseQuestionAction(accessToken: string, id: number, userId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const question: CaseQuestion_case_questions_by_pk = await callHasura(accessToken, fetchCaseQuestionQuery(id))
      if (question.vet_id && question.vet_id !== userId) {
        dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      } else {
        if (!question.vet_id) callHasura(accessToken, claimCaseQuestionQuery(id, userId))
        navigate(`/ai-task?i=${id}`)
      }
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateCaseQuestionAction(accessToken: string, id: number, case_responses_denormalized: any, completed: boolean) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result = await callHasura(accessToken, updateCaseQuestionQuery(id, case_responses_denormalized, completed))
      dispatch(fetchCaseQuestionSuccess(result))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function uploadAiReportToPimsAction(caseId: number) {
  return async (dispatch: any) => {
    dispatch(setLoading(QueryName.UploadAiReportToPims))

    try {
      await callFlask(`/integrations/ai-report/upload?case_id=${caseId}`, 'POST')
      dispatch(setNotificationAction(NotificationId.UploadAiReportToPims))
      dispatch(networkingSuccess(QueryName.UploadAiReportToPims))
    } catch (error) {
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      dispatch(networkingFailure(QueryName.UploadAiReportToPims))
    }
  }
}
