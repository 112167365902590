import React from 'react'
import { HttpLink, split, ApolloClient, ApolloProvider } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'

import CONFIG from './config'
import { hasWindow } from './lib/helpers'

const hasuraHostName = CONFIG.HASURA_API_URL.split('://')[1]

const scheme = (proto: any) => {
  if (!hasWindow || window.location.protocol === 'https:') {
    return `${proto}s`
  } else {
    return proto
  }
}

const wsurl = `${scheme('ws')}://${hasuraHostName}`
const httpurl = `${scheme('http')}://${hasuraHostName}`

const createApolloClient = (getAccessTokenSilently: any) => {
  const wsLink = hasWindow
    ? new WebSocketLink({
        uri: wsurl,
        options: {
          reconnect: true,
          connectionParams: async () => {
            try {
              const accessToken = await getAccessTokenSilently()
              return {
                headers: {
                  'content-type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            } catch (error) {
              console.error(`ERROR getting access token: ${error}`)
              return {}
            }
          },
        },
      })
    : null

  const httpLink = new HttpLink({ uri: httpurl })

  const link = hasWindow
    ? split(
        ({ query }) => {
          // @ts-ignore
          const { kind, operation } = getMainDefinition(query)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        // @ts-ignore
        wsLink,
        httpLink
      )
    : httpLink

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}

export default function ApolloAuthProvider(props: any) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const client = useMemo(() => createApolloClient(getAccessTokenSilently), [isAuthenticated])

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}
