import { createSlice } from '@reduxjs/toolkit'

import {
  deleteDistributionEmailQuery,
  fetchDistributionEmailsQuery,
  insertDistributionEmailQuery,
  UpdateDistributionEmailProps,
  updateDistributionEmailQuery,
} from '../graphQlQueries/DistributionEmail'

import callHasura from '../callHasura'
import { DistributionEmails_distribution_emails } from '../graphQlQueries/types/DistributionEmails'
import { NotificationId, setNotificationAction } from './notifications'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { distribution_emails_insert_input } from '../../../types/globalTypes'
import { fetchUserAction } from './users'

export interface DistributionEmailsState {
  hasErrors: boolean
  loading: boolean
  distributionEmails: DistributionEmails_distribution_emails[]
}

const initialState: DistributionEmailsState = {
  loading: false,
  hasErrors: false,
  distributionEmails: [],
}

const distributionEmailsSlice = createSlice({
  name: 'distributionEmails',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchDistributionEmailsSuccess: (state, { payload }) => {
      state.distributionEmails = payload
    },
  },
})

export const { setLoading, networkingFailure, networkingSuccess, fetchDistributionEmailsSuccess } = distributionEmailsSlice.actions

export const distributionEmailsSelector = (state: any) => state.distributionEmails

export default distributionEmailsSlice.reducer

export function fetchDistributionEmailsAction(accessToken: string, ids: number[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: DistributionEmails_distribution_emails[] = await callHasura(accessToken, fetchDistributionEmailsQuery(ids))
      dispatch(fetchDistributionEmailsSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteDistributionEmailAction(accessToken: string, id: number, userId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteDistributionEmailQuery(id))
      await dispatch(fetchUserAction(accessToken, userId, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertDistributionEmailAction(accessToken: string, object: distribution_emails_insert_input, userId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertDistributionEmailQuery(object))
      await dispatch(fetchUserAction(accessToken, userId, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(setNotificationAction(NotificationId.EmailExists))
      dispatch(networkingFailure())
    }
  }
}

export function updateDistributionEmailAction(accessToken: string, variables: UpdateDistributionEmailProps, userId: string) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateDistributionEmailQuery(variables))
      await dispatch(fetchUserAction(accessToken, userId, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
