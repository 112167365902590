import gql from 'graphql-tag'

export const PRICE_GROUP_FIELDS = gql`
  fragment price_group_fields on price_groups {
    id
    display_name
    organizations_aggregate {
      aggregate {
        count
      }
    }
    prices {
      id
      addon_type
      amount
      consultation_type_id
      currency_type_id
      is_exotic
      price_group_id
      software_charge_type_id
      is_fasttrack_stat
      addon {
        id
        display_name
        additional_data
      }
      stat_type {
        id
        display_name
        hours
      }
    }
  }
`
