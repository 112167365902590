import partition from 'lodash/partition'

import { Case_cases, Case_cases_patient_cases } from '../hasura/graphQlQueries/types/Case'
import { Cases_cases } from '../hasura/graphQlQueries/types/Cases'
import { Consultations_consultations, Consultations_consultations_case } from '../hasura/graphQlQueries/types/Consultations'
import { pluralize } from './helpers'
import { ConsultationsForAiComparison_consultations_case } from '../hasura/graphQlQueries/types/ConsultationsForAiComparison'
import { User_users } from '../hasura/graphQlQueries/types/User'

export enum Modality {
  Xray = 'DX',
  Ultrasound = 'US',
  Catscan = 'CT',
  MRI = 'MRI',
}

export enum ConsultationType {
  Xray = 'DX',
  Ultrasound = 'Ultrasound',
  Catscan = 'CT',
  InternalMedicine = 'Internal Medicine',
  Cardiology = 'Cardiology',
  Neurology = 'Neurology',
  MRI = 'MRI',
}

export enum CtOrMriRegions {
  OneRegion = '1 site',
  TwoRegions = '2 site',
  WholeBody = 'Whole Body',
}

export const sitesToRegions = (sites: CtOrMriSite[]) => {
  if (sites.length < 2) return CtOrMriRegions.OneRegion
  if (sites.length === 2) return CtOrMriRegions.TwoRegions
  return CtOrMriRegions.WholeBody
}

export type CtOrMriSite = CTSite | MRISite

export enum CTSite {
  Head = 'Head',
  Thorax = 'Thorax',
  Abdomen = 'Abdomen',
  ThoracicLimbs = 'Thoracic Limbs',
  PelvicLimbs = 'Pelvic Limbs',
  Other = 'Other',
}

export enum MRISite {
  Brain = 'Brain',
  Cervical = 'Cervical',
  Limbs = 'Limbs',
  Thoraculumbar = 'Thoraculumbar',
  Lumbosacral = 'Lumbosacral',
  Other = 'Other',
}

export const CTSites = Object.values(CTSite)
export const MRISites = Object.values(MRISite)

export interface ConsultationTypeData {
  color: string
  consultationType: ConsultationType
  consultationTypeId: number
  displayName: string
  consultationTypeShort: string
  modality?: Modality
  modalityId?: number
}

export const CONSULTATION_TYPES: ConsultationTypeData[] = [
  {
    modality: Modality.Xray,
    color: '#00a6ed',
    modalityId: 1,
    consultationType: ConsultationType.Xray,
    displayName: 'Radiographs',
    consultationTypeShort: 'DX',
    consultationTypeId: 1,
  },
  {
    modality: Modality.MRI,
    color: '#7E3FF2',
    modalityId: 4,
    consultationType: ConsultationType.MRI,
    displayName: 'MRI',
    consultationTypeShort: 'MRI',
    consultationTypeId: 102,
  },
  {
    modality: Modality.Ultrasound,
    color: '#ffb400',
    modalityId: 2,
    consultationType: ConsultationType.Ultrasound,
    displayName: 'Ultrasound',
    consultationTypeShort: 'US',
    consultationTypeId: 2,
  },
  {
    modality: Modality.Catscan,
    color: '#f6511d',
    modalityId: 3,
    consultationType: ConsultationType.Catscan,
    displayName: 'CT',
    consultationTypeShort: 'CT',
    consultationTypeId: 34,
  },
  {
    consultationType: ConsultationType.InternalMedicine,
    color: '#00B140',
    consultationTypeId: 68,
    consultationTypeShort: 'IM',
    displayName: 'Internal Medicine',
  },
  // Teal (#007C77): A deep teal can serve as a cooler tone to balance the overall warmth of the existing colors, and pairs nicely with both the bright blue and vivid yellow.
]

type Case =
  | Case_cases
  | Cases_cases
  | Consultations_consultations_case
  | Case_cases_patient_cases
  | ConsultationsForAiComparison_consultations_case

export const modalityFor = (c?: Case): Modality => {
  if (isUltrasoundCase(c)) {
    return Modality.Ultrasound
  } else if (isCatscanCase(c)) {
    return Modality.Catscan
  } else if (isMriCase(c)) {
    return Modality.MRI
  } else {
    return Modality.Xray
  }
}

export const getConsultationTypeForModality = (m: Modality) => CONSULTATION_TYPES.find((modality) => modality.modality === m)
export const getConsultationTypeForId = (id: number) => CONSULTATION_TYPES.find((m) => m.consultationTypeId === id)
export const getConsultationTypeData = (consultationType: ConsultationType) =>
  CONSULTATION_TYPES.find((m) => m.consultationType === consultationType)

export const isUltrasoundCase = (c?: Case) =>
  (c?.medical_images || []).some((m) => m.type === getConsultationTypeForModality(Modality.Ultrasound)?.consultationTypeId)

export const isCatscanCase = (c?: Case) =>
  (c?.medical_images || []).some((m) => m.type === getConsultationTypeForModality(Modality.Catscan)?.consultationTypeId)

export const isMriCase = (c?: Case) =>
  (c?.medical_images || []).some((m) => m.type === getConsultationTypeForModality(Modality.MRI)?.consultationTypeId)

export const isXrayCase = (c?: Case) => !isUltrasoundCase(c) && !isCatscanCase(c) && !isMriCase(c)

export const imageAndVideoCountsForUltrasound = (c?: Case) => {
  if (!c?.medical_images.length) return ''

  const [videoCount, imageCount] = partition(c.medical_images, 'is_video')
  return `${pluralize('image', imageCount.length)} / ${pluralize('cineloop', videoCount.length)}`
}

export const getConsultationTypeOptions = (modality: Modality) =>
  modality === Modality.Xray
    ? [
        { value: ConsultationType.Xray, label: 'DX' },
        { value: ConsultationType.InternalMedicine, label: 'Internal Medicine' },
      ]
    : modality === Modality.Ultrasound
    ? [
        { value: ConsultationType.Ultrasound, label: 'US' },
        { value: ConsultationType.InternalMedicine, label: 'Internal Medicine' },
      ]
    : modality === Modality.Catscan
    ? [{ value: ConsultationType.Catscan, label: 'CT' }]
    : [{ value: ConsultationType.MRI, label: 'MRI' }]

export const availableConsultationTypeAfterFirstRequest = (c: Case_cases | Cases_cases) => {
  if (c.consultations.length !== 1 || isCatscanCase(c)) return

  const consultationType = getConsultationTypeForId(c.consultations[0].consultation_type_id)?.consultationType
  return consultationType === ConsultationType.InternalMedicine
    ? isXrayCase(c)
      ? ConsultationType.Xray
      : ConsultationType.Ultrasound
    : ConsultationType.InternalMedicine
}

export const canRequestInternalMedicineConsultation = (user: User_users) =>
  user.organization.price_group.some(
    (p) => p.consultation_type_id === getConsultationTypeData(ConsultationType.InternalMedicine)?.consultationTypeId
  )

export const usesExtraRegionPricing = (modality: Modality) => [Modality.Catscan, Modality.MRI].includes(modality)

export const modalityForConsultation = (consultation: Consultations_consultations) =>
  getConsultationTypeForId(consultation.consultation_type_id)?.modality!

export const consultationUsesExtraRegionPricing = (consultation: Consultations_consultations) =>
  usesExtraRegionPricing(modalityForConsultation(consultation))
