import { createSlice } from '@reduxjs/toolkit'

import { palette } from '../../lib/cssHelpers'
import { sleep } from '../../lib/helpers'

const NOTIFICATION_DURATION_SECONDS = 3

export const NOTIFICATION_TRANSITION_SECONDS = 0.2

export enum NotificationId {
  AddendumCreated,
  AllClaimedConsultationCompleted,
  AlreadyClaimed,
  CaseCreated,
  CaseQuestionCreated,
  CaseQuestionsCompleted,
  CheckYourEmail,
  ConditionCreated,
  ConsultationCompleted,
  ConsultationEdited,
  ConsultationLocked,
  ConsultationRequested,
  EmailExists,
  IncorrectFileType,
  InvalidAmount,
  InvalidParameters,
  NoResults,
  PatientDataImported,
  PayRateSaved,
  PriceGroupCreated,
  PriceSaved,
  ReferralsSent,
  SettingsUpdated,
  SomethingBadHappened,
  ThankYouForFeedback,
  UpgradedStat,
  UploadAiReportToPims,
}

interface Notification {
  id: NotificationId
  title: string
  color?: string
}

const notifications: Map<number, Notification> = new Map([
  [
    NotificationId.AllClaimedConsultationCompleted,
    { id: NotificationId.AllClaimedConsultationCompleted, title: 'All Claimed Completed', color: palette.primary },
  ],
  [NotificationId.ConsultationCompleted, { id: NotificationId.ConsultationCompleted, title: 'Consultation Completed' }],
  [NotificationId.ConsultationEdited, { id: NotificationId.ConsultationEdited, title: 'Consultation Edited' }],
  [NotificationId.ConsultationRequested, { id: NotificationId.ConsultationRequested, title: 'Consultation Requested' }],
  [NotificationId.SettingsUpdated, { id: NotificationId.SettingsUpdated, title: 'Settings Updated' }],
  [NotificationId.ConditionCreated, { id: NotificationId.ConditionCreated, title: 'Condition Created' }],
  [NotificationId.CaseCreated, { id: NotificationId.CaseCreated, title: 'Case Created' }],
  [NotificationId.UpgradedStat, { id: NotificationId.UpgradedStat, title: 'Upgraded to STAT' }],
  [NotificationId.ConsultationLocked, { id: NotificationId.ConsultationLocked, title: 'Consultation Locked', color: palette.red }],
  [NotificationId.InvalidAmount, { id: NotificationId.InvalidAmount, title: 'Invalid Amount', color: palette.red }],
  [NotificationId.EmailExists, { id: NotificationId.EmailExists, title: 'Email Already Exists', color: palette.red }],
  [NotificationId.AlreadyClaimed, { id: NotificationId.AlreadyClaimed, title: 'Already Claimed', color: palette.red }],
  [NotificationId.ThankYouForFeedback, { id: NotificationId.ThankYouForFeedback, title: 'Thank You For Your Feedback' }],
  [NotificationId.CaseQuestionCreated, { id: NotificationId.CaseQuestionCreated, title: 'Vet Query Created' }],
  [
    NotificationId.SomethingBadHappened,
    { id: NotificationId.SomethingBadHappened, title: 'Something Bad Happened', color: palette.red },
  ],
  [NotificationId.AddendumCreated, { id: NotificationId.AddendumCreated, title: 'Addendum Created' }],
  [NotificationId.NoResults, { id: NotificationId.NoResults, title: 'No Results', color: palette.red }],
  [NotificationId.InvalidParameters, { id: NotificationId.InvalidParameters, title: 'Invalid Parameters', color: palette.red }],
  [NotificationId.IncorrectFileType, { id: NotificationId.IncorrectFileType, title: 'Incorrect File Type', color: palette.red }],
  [NotificationId.CheckYourEmail, { id: NotificationId.CheckYourEmail, title: 'Check Your Email' }],
  [
    NotificationId.CaseQuestionsCompleted,
    { id: NotificationId.CaseQuestionsCompleted, title: 'Thank you! This will be added to your next payout.' },
  ],
  [NotificationId.ReferralsSent, { id: NotificationId.ReferralsSent, title: 'Invite(s) Sent' }],
  [NotificationId.PriceGroupCreated, { id: NotificationId.PriceGroupCreated, title: 'Price Group Created' }],
  [NotificationId.PriceSaved, { id: NotificationId.PriceSaved, title: 'Price Saved' }],
  [NotificationId.PayRateSaved, { id: NotificationId.PayRateSaved, title: 'Pay Rate Saved' }],
  [NotificationId.PatientDataImported, { id: NotificationId.PatientDataImported, title: 'Patient Data Imported' }],
  [NotificationId.UploadAiReportToPims, { id: NotificationId.UploadAiReportToPims, title: 'Initial Assessment Sent To PIMS' }],
])

export interface DownloadLink {
  url: string
  text: string
}

export interface NotificationsState {
  notification?: Notification
  notificationOpacity: number
  downloadLinks?: DownloadLink[]
}

const initialState: NotificationsState = {
  notificationOpacity: 0,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification: (state, { payload }) => {
      state.notification = payload
      state.notificationOpacity = 1
    },

    setDownloadLinks: (state, { payload }: { payload: DownloadLink[] | undefined }) => {
      state.downloadLinks = payload
    },

    hideNotification: (state) => {
      state.notificationOpacity = 0
    },

    removeNotification: (state) => {
      state.notification = undefined
    },
  },
})

export const { setNotification, hideNotification, removeNotification, setDownloadLinks } = notificationsSlice.actions

export const notificationsSelector = (state: any) => state.notifications

export default notificationsSlice.reducer

export function setNotificationAction(notificationId: NotificationId) {
  return async (dispatch: any) => {
    dispatch(setNotification(notifications.get(notificationId)))
    await sleep(NOTIFICATION_DURATION_SECONDS - NOTIFICATION_TRANSITION_SECONDS)
    dispatch(hideNotification())
    await sleep(NOTIFICATION_TRANSITION_SECONDS)
    dispatch(removeNotification())
  }
}

export function setDownloadLinksAction(links?: DownloadLink[]) {
  return async (dispatch: any) => {
    dispatch(setDownloadLinks(links))
  }
}
