import partition from 'lodash/partition'
import groupBy from 'lodash/groupBy'

import CONFIG from '../config'
import { Case_cases, Case_cases_dicom_server_organization_price_group } from '../hasura/graphQlQueries/types/Case'
import { CompletedConsultation } from '../hasura/slices/consultations'
import { ConsultationType, CtOrMriRegions, Modality, getConsultationTypeData, modalityFor } from '../lib/modalityHelpers'
import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'
import { EnterpriseBilling_overflow_consults } from '../hasura/graphQlQueries/types/EnterpriseBilling'
import { SpecialistPayments_specialist_payments } from '../hasura/graphQlQueries/types/SpecialistPayments'
import { Species, isExotic, requestedImagesCount } from '../lib/helpers'
import { StripeInvoiceItem } from '../interfaces/StripeInvoiceItem'
import { User_users } from '../hasura/graphQlQueries/types/User'
import { VetStatus } from '../hasura/slices/users'
import { additionalImagesCount } from '../lib/combineCompareHelpers'
import { getExtraImagePrices, getStatPriceAmount, getWeekendStatPrice, statDescription } from '../lib/pricingHelpers'
import { isRadimalReaderLoggedInOtherEnterprise } from '../lib/userHelpers'

const US_VIDEO_THRESHOLD = 15
const US_IMAGE_THRESHOLD = 60
const US_EXTRA_IMAGE_VIDEO_SPECIALIST_PAY = 25

export default class PriceCalculator {
  case: Case_cases

  DEFAULT_PRICE = 65

  constructor(c: Case_cases) {
    this.case = c
  }

  costs(
    otherCases: string | null,
    removedImagesCount: number,
    removedVideosCount: number,
    species: Species,
    consultationType: ConsultationType,
    statTypeId?: number,
    region?: CtOrMriRegions
  ) {
    const patientIsExotic = isExotic(species)
    const modality = getConsultationTypeData(consultationType)?.modality || Modality.Xray
    const prices = (this.case.dicom_server?.organization?.price_group || []).filter((p) =>
      patientIsExotic ? p.is_exotic : !p.is_exotic
    )

    // get base price
    const basePrice: number =
      prices.find(
        (p) =>
          !p.software_charge_type_id &&
          !p.stat_type &&
          !p.addon_type &&
          !p.addon &&
          p.consultation_type_id === getConsultationTypeData(consultationType)?.consultationTypeId
      )?.amount || this.DEFAULT_PRICE

    // get stat price
    let statPrice = this.case && statTypeId ? getStatPriceAmount(statTypeId, this.case, modality) : 0
    if (statTypeId) statPrice += getWeekendStatPrice(prices)

    // get extra image/region price
    const extraImageCost = (() => {
      const extraImageXrayPrices = getExtraImagePrices(prices, patientIsExotic, modality).reverse()

      if ([ConsultationType.Catscan, ConsultationType.MRI].includes(consultationType) && region) {
        const price = this.priceForSites(region, prices, getConsultationTypeData(consultationType)?.consultationTypeId)
        if (price) return [price.amount, 0]
      } else if (consultationType === ConsultationType.Xray) {
        const imagesCount = this.case.medical_images.length + additionalImagesCount(otherCases) - removedImagesCount

        for (const price of extraImageXrayPrices) {
          const thresholds = price.addon?.additional_data
          if (imagesCount > thresholds['threshold']) {
            return [price.amount, `${thresholds['threshold']} images`]
          }
        }
      } else if (consultationType === ConsultationType.Ultrasound) {
        const [videosCount, imagesCount] = partition(this.case.medical_images, 'is_video').map((a) => a.length)
        for (const price of extraImageXrayPrices) {
          const thresholds = price.addon?.additional_data
          if (
            imagesCount - removedImagesCount > thresholds['image_threshold'] ||
            videosCount - removedVideosCount > thresholds['video_threshold']
          ) {
            return [price.amount, `${thresholds['image_threshold']} images / ${thresholds['video_threshold']} cineloops`]
          }
        }
      }
    })()

    const totalPriceWithoutStat = basePrice + (extraImageCost ? extraImageCost[0] : 0)
    // extra image cost applied at time of consult completion since images may come in after request
    const totalPriceWithoutExtraImageCost = basePrice + statPrice

    return {
      basePrice,
      extraImageCost,
      statPrice,
      totalPriceWithoutExtraImageCost,
      totalPriceWithoutStat,
    }
  }

  priceForSites(region: CtOrMriRegions, priceGroups: Case_cases_dicom_server_organization_price_group[], consultationTypeId?: number) {
    const extraRegionsPriceGroups = priceGroups.filter(
      (p) => p.addon?.display_name === 'Extra Regions' && p.consultation_type_id === consultationTypeId
    )
    switch (region) {
      case CtOrMriRegions.WholeBody:
        return extraRegionsPriceGroups.find((p) => p.addon?.additional_data.threshold === 2)
      case CtOrMriRegions.TwoRegions:
        return extraRegionsPriceGroups.find((p) => p.addon?.additional_data.threshold === 1)
      case CtOrMriRegions.OneRegion:
        return
    }
  }

  static invoiceItemsFromConsultations(
    completedConsultations: (CompletedConsultation | EnterpriseBilling_overflow_consults)[],
    isOverflow = false
  ) {
    return Object.entries(
      groupBy(completedConsultations, (c) => {
        let description = `${isOverflow ? 'Overflow ' : ''}${c.consultation_type.display_name} full consult`

        if (c.stat_type) {
          description += ` ${statDescription(c.stat_type)}`
        }

        return [description, isOverflow ? c.overflow_price_amount : c.price_amount]
      })
    ).map(
      (arr): StripeInvoiceItem => {
        const [description, price] = arr[0].split(',')
        return { description, unit_amount: parseFloat(price) * 100 || 0, quantity: arr[1].length }
      }
    )
  }

  static receivingVetPayAmount = (
    specialistPayments: SpecialistPayments_specialist_payments[],
    consultation?: Consultations_consultations,
    vetStatus?: VetStatus,
    user?: User_users,
    sites?: string[]
  ) => {
    const caseEnterpriseId = consultation?.case.dicom_server?.organization?.enterprise.id
    const isRadimalCase = caseEnterpriseId === CONFIG.RADIMAL_ENTERPRISE_ID
    const isEnterpriseReader = user?.user_roles.some((r) => r.enterprise.id === caseEnterpriseId)
    const disableBonuses = !isRadimalCase && isEnterpriseReader

    const [baseSpecialistPayments, addonSpecialistPayments] = partition(specialistPayments, (s) => !s.addon)

    const filteredSpecialistPayments = baseSpecialistPayments.some(
      (s) => caseEnterpriseId === s.specialist_payment_group?.enterprise_id
    )
      ? baseSpecialistPayments.filter((s) => caseEnterpriseId === s.specialist_payment_group?.enterprise_id)
      : baseSpecialistPayments.filter((s) => {
          if (isRadimalReaderLoggedInOtherEnterprise(user)) {
            return s.specialist_payment_group?.enterprise_id === CONFIG.RADIMAL_ENTERPRISE_ID
          }

          return s.specialist_payment_group?.enterprise_id === user?.organization.enterprise.id
        })

    // get the amount for the stat type, species, and modality
    const modality = modalityFor(consultation?.case)
    const patientIsExotic = isExotic(consultation?.case.patient.species)
    let amount: number | undefined = filteredSpecialistPayments.find((s) => {
      const matchesStatType = s.stat_type_id === (consultation?.stat_type?.id || null)
      const matchesExotic = patientIsExotic ? s.is_exotic : !s.is_exotic
      const matchesModality = s.consultation_type_id === consultation?.consultation_type_id
      return matchesStatType && matchesExotic && matchesModality
    })?.amount
    let paymentDescription = `${amount} base`

    // add bonuses
    const applyStatPlusBonus = !disableBonuses && Boolean(vetStatus?.stat_plus_vet_ids.includes(user?.id || ''))
    if (!amount || !consultation) return { amount, amountWithBonuses: 0, applyStatPlusBonus }

    if (!disableBonuses) {
      const addStatBonus = Boolean(vetStatus?.stat_bonus_activated && consultation?.stat_type)
      if (addStatBonus) {
        amount += CONFIG.STAT_OFF_HOUR_BONUS
        paymentDescription += `; stat_off_hours`
      }
      if (vetStatus?.holiday_bonus_activated) {
        amount += CONFIG.HOLIDAY_BONUS
        paymentDescription += `; holiday`
      }

      const extraImageSpecialistPayments = getExtraImagePrices(addonSpecialistPayments, patientIsExotic, modality).reverse()

      // add extra image cost
      if (modality === Modality.Xray) {
        const imagesCount = requestedImagesCount(consultation)
        for (const price of extraImageSpecialistPayments) {
          const thresholds = price.addon?.additional_data
          if (imagesCount > thresholds['threshold']) {
            amount += price.amount
            paymentDescription += `; extra_images`
            break
          }
        }
      } else if (modality === Modality.Ultrasound) {
        // TODO: - use addon table for thresholds and model in specialist payments table
        const [videosCount, imagesCount] = partition(consultation.case.medical_images, 'is_video').map((a) => a.length)
        if (videosCount > US_VIDEO_THRESHOLD || imagesCount > US_IMAGE_THRESHOLD) amount += US_EXTRA_IMAGE_VIDEO_SPECIALIST_PAY
      } else if ([Modality.Catscan, Modality.MRI].includes(modality) && sites?.length) {
        const payment = extraImageSpecialistPayments.find((p) => {
          const threshold = p.addon?.additional_data.threshold
          if (sites.length > 2) return threshold === 2
          if (sites.length === 2) return threshold === 1
        })
        if (payment) {
          amount += payment.amount
          paymentDescription += `; extra_regions`
        }
      }
    }

    let amountWithBonuses
    if (applyStatPlusBonus) {
      amountWithBonuses = amount + (consultation?.stat_type ? CONFIG.STAT_LIGHT_ON_BONUS : CONFIG.STAT_LIGHT_ON_BONUS_NON_STAT)
      paymentDescription += `; stat_light_bonus`
    } else {
      amountWithBonuses = amount
    }

    return { amount, amountWithBonuses, applyStatPlusBonus, paymentDescription }
  }
}
