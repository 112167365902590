import gql from 'graphql-tag'
import moment from 'moment'

import { QueryName } from '../queryNames'
import { Species } from '../../lib/helpers'
import { TaggingOption } from '../../lib/aiHelpers'
import { MEDICAL_IMAGE_FIELDS } from './fragments/medicalImageFields'
import { TRAINING_ITERATION_FIELDS } from './fragments/trainingIterationFields'

export interface FetchMedicalImagesParams {
  species: Species
  option: TaggingOption
  inConsult: boolean
  conditionId?: number
}

// using dynamic strings in queries causes the graphql codegen error: "Validation of GraphQL query document failed",
// but is easier to do at the moment rather than writing many queries based on these numerous conditions
export const fetchMedicalImagesQuery = (params?: FetchMedicalImagesParams, ids?: number[], name = QueryName.MedicalImages) => {
  let limit = 9999
  let where = ''

  if (params) {
    if (params.option === TaggingOption.Tag) limit = 100

    where =
      'where: { flagged: { _eq: false }, _or: [{ case: { patient: { species: { _eq: $species } } } }, { species: { _eq: $species } }]'

    if (params.option === TaggingOption.Tag) {
      if (params.inConsult) {
        where += ', _and: { case: { consultation: { id: { _is_null: false } } }'
      } else {
        where += ', _and: { case_id: { _is_null: true }'
      }

      where += ', _and: { _not: { predictions_normalizeds: { type: { _eq: "specialist-medical_image" } } } } } }'
    }

    if (params.option === TaggingOption.Review && params.conditionId) {
      where +=
        ', _and: { predictions_normalizeds: { condition_id: { _eq: $conditionId }, _and: { type: { _eq: "specialist-medical_image" } } } } }'
    }
  }

  if (ids?.length) {
    where = 'where: { id: { _in: $ids } }'
  }

  return {
    query: gql`
      ${MEDICAL_IMAGE_FIELDS}
      query MedicalImages($species: String, $limit: Int, $conditionId: Int, $ids: [Int!]) {
        medical_images(order_by: { id: desc }, limit: $limit, ${where}) {
          ...medical_images_fields
        }
      }
    `,
    route: 'medical_images',
    variables: { conditionId: params?.conditionId, species: params?.species, limit, ids },
    name,
  }
}

export const fetchMedicalImageQuery = (id: number) => ({
  query: gql`
    query MedicalImage($id: Int!) {
      medical_images_by_pk(id: $id) {
        id
        aws_s3_url
        flagged
        species
        view {
          id
        }
        dicom_server_instance_id
        case {
          id
          dicom_server_study_instance_uid
          dicom_source
          patient {
            id
            display_name
            species
            breed
          }
          consultations {
            id
            predictions {
              id
              display_name
              condition_category {
                id
                display_name
              }
              condition {
                id
                display_name
                category {
                  display_name
                }
              }
            }
            sending_vet_notes
            receiving_vet_notes
          }
        }
        predictions_normalizeds(where: { environment: { _eq: "production" }, flagged_for_deletion: { _eq: false } }) {
          id
          display_name
          grade
          issue
          training_iteration_id
          type
          vet_id
          key_values_json
          bounding_box
          condition_category {
            display_name
            order_index
          }
          condition {
            id
            display_name
            ai_notes
            category {
              display_name
              order_index
            }
          }
        }
      }
    }
  `,
  route: 'medical_images_by_pk',
  variables: { id },
  name: QueryName.FetchMedicalImage,
})

export const updateMedicalImageFlaggedQuery = (id: number, flagged: boolean, name = QueryName.UpdateMedicalImageFlagged) => ({
  query: gql`
    mutation UpdateMedicalImageFlagged($id: Int!, $flagged: Boolean!) {
      update_medical_images_by_pk(pk_columns: { id: $id }, _set: { flagged: $flagged }) {
        id
      }
    }
  `,
  route: 'update_medical_images_by_pk',
  variables: { id, flagged },
  name,
})

export const updateTrainingIterationFeedbackQuery = (id: number, feedback: string) => ({
  query: gql`
    mutation UpdateTrainingIterationFeedback($id: Int!, $feedback: String!) {
      update_training_iterations_by_pk(pk_columns: { id: $id }, _set: { feedback: $feedback }) {
        id
      }
    }
  `,
  route: 'update_training_iterations_by_pk',
  variables: { id, feedback },
})

export const fetchMedicalImageViewsQuery = (name = QueryName.MedicalImageViews) => ({
  query: gql`
    query MedicalImageViews {
      medical_image_views {
        id
        display_name
      }
    }
  `,
  route: 'medical_image_views',
  name,
})

export const updateMedicalImageViewQuery = (id: number, view_id: number, name = QueryName.UpdateMedicalImageView) => ({
  query: gql`
    mutation UpdateMedicalImageView($id: Int!, $view_id: Int!) {
      update_medical_images_by_pk(pk_columns: { id: $id }, _set: { view_id: $view_id }) {
        id
      }
    }
  `,
  route: 'update_medical_images_by_pk',
  variables: { id, view_id },
  name,
})

export const updateMedicalImageSpeciesQuery = (id: number, species: String) => ({
  query: gql`
    mutation UpdateMedicalImageSpecies($id: Int!, $species: String!) {
      update_medical_images_by_pk(pk_columns: { id: $id }, _set: { species: $species }) {
        id
      }
    }
  `,
  route: 'update_medical_images_by_pk',
  variables: { id, species },
})

export const trainingIterationQuery = (id: number) => ({
  query: gql`
    ${TRAINING_ITERATION_FIELDS}
    query TrainingIteration($id: Int!) {
      training_iterations_by_pk(id: $id) {
        ...training_iterations_fields
      }
    }
  `,
  route: 'training_iterations_by_pk',
  variables: { id },
})

export const completeTrainingIterationQuery = (id: number, completed_at = moment()) => ({
  query: gql`
    mutation CompleteTrainingIteration($id: Int!, $completed_at: timestamptz) {
      update_training_iterations_by_pk(pk_columns: { id: $id }, _set: { completed_at: $completed_at }) {
        id
      }
    }
  `,
  route: 'update_training_iterations_by_pk',
  variables: { id, completed_at },
})

export const fetchTrainingIterationsQuery = (name = QueryName.FetchTrainingIterations) => ({
  query: gql`
    ${TRAINING_ITERATION_FIELDS}
    query TrainingIterations {
      training_iterations(where: { completed_at: { _is_null: true } }, order_by: { id: desc }) {
        ...training_iterations_fields
      }
    }
  `,
  route: 'training_iterations',
  name,
})

export const fetchTrainingIterationMetricsQuery = (name = QueryName.FetchTrainingIterations) => ({
  query: gql`
    ${TRAINING_ITERATION_FIELDS}
    query TrainingIterationMetrics {
      training_iterations(order_by: { id: desc }) {
        ...training_iterations_fields
      }
    }
  `,
  route: 'training_iterations',
  name,
})

export const medicalImagesForConditionQuery = (id: number, name = QueryName.MedicalImagesForCondition) => ({
  query: gql`
    query MedicalImagesForCondition($id: Int!) {
      medical_images(
        order_by: { id: desc }
        where: {
          predictions_normalizeds: {
            _or: [{ type: { _ilike: "%specialist-medical_image%" } }, { type: { _ilike: "%specialist-medical_image-binary_tagger%" } }]
            condition_id: { _eq: $id }
          }
        }
      ) {
        id
        aws_s3_url
        case {
          consultations {
            receiving_vet {
              id
              display_name
            }
            predictions {
              condition {
                id
                display_name
              }
            }
          }
        }
        view {
          display_name
        }
        medical_image_permutations {
          aws_s3_url
          label
          confidence
        }
        predictions_normalizeds(
          where: {
            flagged_for_deletion: { _eq: false }
            _or: [{ type: { _ilike: "%specialist-medical_image%" } }, { type: { _ilike: "%specialist-medical_image-binary_tagger%" } }]
          }
        ) {
          type
          created_at
          display_name
          grade
          issue
          key_values_json
          bounding_box
          condition {
            id
            display_name
          }
          user {
            id
            display_name
          }
        }
      }
    }
  `,
  route: 'medical_images',
  variables: { id },
  name,
})

export const fetchMedicalImageUrlsQuery = (ids: number[]) => ({
  query: gql`
    query MedicalImagesUrls($ids: [Int!]!) {
      medical_images(where: { id: { _in: $ids } }) {
        id
        aws_s3_url
      }
    }
  `,
  route: 'medical_images',
  variables: { ids },
})

export const claimTrainingIterationQuery = (id: number, vet_id: string) => ({
  query: gql`
    mutation ClaimTrainingIteration($id: Int!, $vet_id: String!) {
      update_training_iterations_by_pk(pk_columns: { id: $id }, _set: { vet_id: $vet_id }) {
        id
      }
    }
  `,
  route: 'update_training_iterations_by_pk',
  variables: { id, vet_id },
})
