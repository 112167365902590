export enum QueryName {
  CompleteConsultation = 'CompleteConsultation',
  CompletedConsultations = 'CompletedConsultations',
  ConditionPredictionCounts = 'ConditionPredictionCounts',
  Consultations = 'Consultations',
  DownloadStudies = 'DownloadStudies',
  DraftInvoiceBatch = 'DraftInvoiceBatch',
  FetchCase = 'FetchCase',
  FetchCases = 'FetchCases',
  FetchConsultationsForAiComparison = 'FetchConsultationsForAiComparison',
  FetchMedicalImage = 'FetchMedicalImage',
  FetchOrganizationEvents = 'FetchOrganizationEvents',
  FetchTrainingIterations = 'FetchTrainingIterations',
  FetchVetStatuses = 'FetchVetStatuses',
  InsertPrice = 'InsertPrice',
  InsertSpecialistPayment = 'InsertSpecialistPayment',
  MedicalImageViews = 'MedicalImageViews',
  MedicalImages = 'MedicalImages',
  MedicalImagesForCondition = 'MedicalImagesForCondition',
  PostInstance = 'PostInstance',
  RequestConsultation = 'RequestConsultation',
  SendReferrals = 'SendReferrals',
  SpecialistPredictions = 'SpecialistPredictions',
  UpdateConditionActiveLearning = 'UpdateConditionActiveLearning',
  UpdateConsultationNotes = 'UpdateConsultationNotes',
  UpdateMedicalImageFlagged = 'UpdateMedicalImageFlagged',
  UpdateMedicalImageView = 'UpdateMedicalImageView',
  UpdateVetStatCalendar = 'UpdateVetStatCalendar',
  UpgradeStat = 'UpgradeStat',
  UploadAiReportToPims = 'UploadAiReportToPims',
}
