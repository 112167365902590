import gql from 'graphql-tag'

export const COMPLETED_CONSULTATION_FIELDS = gql`
  fragment completed_consultation_fields on consultations {
    id
    charge_adjustment_notes
    completed_at
    completed_by_enterprise_id
    consultation_type_id
    created_at
    display_id
    distribution_email_ids_denormalized
    overflow_price_amount
    price_amount
    receiving_vet_paid
    receiving_vet_pay_amount
    s3_url
    sending_vet_notes
    stat_type_id
    status
    upgraded_at
    consultation_type {
      display_name
    }
    addendums(where: { text: { _is_null: true } }) {
      id
    }
    stat_type {
      hours
      display_name
    }
    consultation_linked_cases {
      type
      case {
        medical_images_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    case {
      additional_data
      patient {
        species
        display_name
      }
      medical_images_aggregate {
        aggregate {
          count
        }
      }
      dicom_server {
        organization {
          id
          display_name
          enterprise {
            id
            logo_aws_s3_url
            short_name
          }
        }
      }
    }
    receiving_vet {
      id
      display_name
    }
  }
`
